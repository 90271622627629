<template>
  <div class="flex-row align-items-center">
    <div class="container">
      <div class="bg-light">
      <div class="row">
        <div class="col h3 mt-1 text-info">a. Chức năng chính</div>
      </div>
      <div>
        <div class="col">
          <div class="h4">1. Công ty có chi nhánh: có 4 loại user [admin, sub_admin, admin chi nhánh, user]</div>
          <div>
            <div class="col">
              <div class="h5">1.1 Phân quyền:</div>
              <div>
                <div class="col">
                  <span class="h6">1.1.1 Admin</span>: Người có quyền cao nhất trong hệ thống,
                  được tạo khi đăng ký thành công tại <a class="text-primary" href="http://hansan.vn/dang-ky">hansan</a>
                  <ul>
                    <li>Quản lý danh sách admin , sub_admin, user của chi nhánh</li>
                    <li>quản lý danh sách sản phẩm</li>
                    <li>Quản lý danh sách Order</li>
                    <li>Quản lý danh sách Khách hàng</li>
                    <li>Quản lý thông tin công ty (hay tiệm cầm đồ), địa chỉ, điện thoại, logo, giờ làm việc</li>
                    <li>Xem báo cáo doanh thu, ngày, tháng, quý, xuất báo cáo của công ty (hay quán cafe), và của tất cả chi nhánh thuộc công ty</li>
                  </ul>
                </div>
                <div class="col">
                  <span class="h6">1.1.2 Admin chi nhánh</span>:
                    Người có quyền cao nhất 1 chi nhánh trong hệ thống, quyền này được Admin tạo để quản lý 1 chi nhánh
                  <ul>
                  <li>Quản lý thông tin chi nhánh, điện thoại, địa chỉ, logo, giờ làm việc</li>
                  <li>Quản lý thông tin user của chi nhánh</li>
                  <li>Quản lý danh sách sản phẩm giao dịch của chi nhánh</li>
                  <li>Quản lý danh sách khách hàng của chi nhánh</li>
                  <li>Quản lý danh sách order của chi nhánh</li>
                  <li>Xem cáo báo doanh thu ngày, tháng, quý của chi nhánh</li>
                  </ul>
                </div>
                <div class="col">
                  <span class="h6">1.1.3 Sub Admin</span>:
                  Người này được Admin tạo, thuộc quyền quản lý của Admin và có quyền quản lý khách hàng, sản phẩm.....trong hệ thống
                  <ul>
                  <li>Quản lý danh sách giao dịch của cả công ty, bao gồm của tất cả chi nhánh</li>
                  <li>Quản lý danh sách sản phẩm của công ty và của tất cả chi nhánh</li>
                  </ul>
                </div>
                <div class="col">
                  <span class="h6">1.1.4 User</span>:
                  Người có thể do Admin hay Admin chi nhánh tạo và thuộc 1 chi nhánh cụ thể
                  <ul>
                    <li>Xem và tạo order giao dịch của chi nhánh</li>
                    <li>Quản lý danh sách sản phẩm của chi nhánh</li>
                  </ul>
                </div>
              </div>
              <div class="h5">1.2 Chức năng:</div>
              <div>
                <div class="col"><span class="h6">1.2.1 Tạo chi nhánh</span>:
                    Chi nhánh được tạo bởi quyền cao nhất Admin. Khi người dùng đăng ký tại hansan và kích hoạt tài khoản thành công, sau đó
                    đăng nhập vào hệ thống làm như sau:
                    <ul>
                    <li>Nhấp chọn thẻ 'DS chi nhánh' bên menu trái màn hình</li>
                    <li>Nhập thông tin "tên chi nhánh, điện thoại, địa chỉ, mã chi nhánh"</li>
                    <li>Nhấn nút "Save" ==>chi nhánh được tạo và xuất thông báo thành công bên góc phải màn hình</li>
                    </ul>
                </div>
                <div class="col"><span class="h6">1.2.2 Tạo Admin chi nhánh</span>:
                    Admin chi nhánh được tạo bởi quyền cao nhất Admin. Khi người dùng đăng ký tại hansan và kích hoạt tài khoản thành công, sau đó
                    đăng nhập vào hệ thống làm như sau:
                    <ul>
                    <li>Nhấp chọn thẻ 'DS user' bên menu trái màn hình</li>
                    <li>Nhập thông tin "họ tên, giới tính, điện thoại, email, quyền [trưởng chi nhánh], địa chỉ"</li>
                    <li>Nhấn nút "Save" ==>Admin chi nhánh được tạo và xuất thông báo thành công bên góc phải màn hình</li>
                    </ul>
                </div>
                <div class="col"><span class="h6">1.2.3 Tạo User của chi nhánh</span>:
                    có 2 cách <br/>
                    <i class="ml-3">+ cách 1. tạo bởi quyền Admin:</i>
                    <ul>
                        <li>Admin đăng nhập thành công vào hệ thống</li>
                        <li>Nhấn chọn thẻ DS user bên góc trái màn hình</li>
                        <li>nhập (họ tên, giới tính, điện thoại, email, quyền [chọn User], chi nhánh, địa chỉ)</li>
                        <li>Nhấn nút "Save" ==>User chi nhánh được tạo và xuất thông báo thành công bên góc phải màn hình</li>
                    </ul>
                    <i class="ml-3">+ cách 2. tạo bởi quyền Admin-chi nhánh:</i>
                    <ul>
                        <li>Admin-chi nhánh đăng nhập thành công vào hệ thống</li>
                        <li>Nhấn chọn thẻ "DS user" bên góc trái màn hình</li>
                        <li>nhập (họ tên, giới tính, điện thoại, email, quyền [chọn User], địa chỉ)</li>
                        <li>Nhấn nút "Save" ==>User chi nhánh được tạo và xuất thông báo thành công bên góc phải màn hình</li>
                    </ul>
                </div>
                <div class="col">
                  <span class="h6">1.2.4 Tạo sản phẩm</span>: có thể được tạo bởi Admin, Admin-chi nhánh, Sub Admin, User
                  <ul>
                    <li>Người dùng đăng nhập vào hệ thống thành công bằng 1 trong 4 quyền [admin, admin-chi nhánh, sub_admin, user]</li>
                    <li>Nhấn chọn thẻ "DS sản phẩm" bên góc menu trái màn hình</li>
                    <li>Nhập (tên sản phẩm, mã sản phẩm, giá bán,địa chỉ)</li>
                    <li>Nhấn nút "Save" ==>Sản phẩm được tạo và xuất thông báo thành công bên góc phải màn hình</li>
                  </ul>
                </div>
                <div class="col">
                  <span class="h6">1.2.5 Tạo order</span>: có thể được tạo bởi Admin, Admin-chi nhánh, Sub Admin, User
                  <ul>
                    <li>Người dùng đăng nhập vào hệ thống thành công bằng 1 trong 4 quyền [admin, admin-chi nhánh, sub_admin, user]</li>
                    <li>Nhấn chọn thẻ "DS order" bên góc menu trái màn hình</li>
                    <li>Nhập (chọn bàn, giờ vào, mô tả, món, giá tiền, đơn vị số lượng, tổng tiền)</li>
                    <li>Nhấn nút "Save" ==>Order được tạo và xuất thông báo thành công bên góc phải màn hình</li>
                  </ul>
                </div>
                <div class="col">
                  <span class="h6">1.2.6 Xem báo cáo doanh số</span>: chỉ có Admin, Admin-chi nhánh có thể xem
                  <ul>
                    <li>Người dùng đăng nhập vào hệ thống thành công bằng 1 trong 2 quyền [admin, admin-chi nhánh]</li>
                    <li>Nhấn chọn thẻ "Báo cáo-> doanh thu ngày" bên góc menu trái màn hình</li>
                    <li>Nhập (chọn chi nhánh,từ ngày,đến ngày)==>nhấn nút "Xem báo cáo" để xem báo cáo</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="h4">
            1. Công ty không chi nhánh: có 2 loại user [admin, sub_admin]
          </div>
            <div class="col">
              <div class="h5">1.1 Phân quyền:</div>
              <div>
                <div class="col">
                  <span class="h6">1.1.1 Admin:</span>
                  Người có quyền cao nhất trong hệ thống, khi bạn đăng ký user sử dụng tại hansan, quyền admin này được tạo đầu tiên
                  <ul>
                   <li>Quản lý danh sách Admin, Sub Admin </li>
                   <li>quản lý danh sách sản phẩm</li>
                   <li>Quản lý danh sách Order, khách hàng</li>
                   <li>Quản lý thông tin công ty (hay tiệm cầm đồ), địa chỉ, điện thoại, logo</li>
                   <li>Xem báo cáo doanh thu, ngày, tháng, quý, xuất báo cáo của công ty (hay tiệm cầm đồ)</li>
                  </ul>
                </div>
                <div class="col">
                  <span class="h6">1.1.3 Sub Admin</span>:
                  Người này được Admin tạo, thuộc quyền quản lý của Admin và có quyền quản lý order, sản phẩm.....trong hệ thống
                  <ul>
                    <li>Quản lý danh sách Order, Khách hàng</li>
                    <li>Quản lý danh sách sản phẩm</li>
                  </ul>
                </div>
              </div>
              <div class="h6">1.2 Chức năng:</div>
              <div>
                <div class="col">
                  <span class="h6">1.2.1 Tạo sản phẩm</span>:
                  có thể được tạo bởi [Admin, Sub Admin]
                  <ul>
                    <li>Người dùng đăng nhập vào hệ thống thành công bằng 1 trong 2 quyền [admin, sub_admin]</li>
                    <li>Nhấn chọn thẻ "DS sản phẩm" bên góc menu trái màn hình</li>
                    <li>Nhập (tên sản phẩm, mã sản phẩm, giá bán,địa chỉ)</li>
                    <li>Nhấn nút "Save" ==>Sản phẩm được tạo và xuất thông báo thành công bên góc phải màn hình</li>
                  </ul>
                </div>
                <div class="col">
                  <span class="h6">1.2.2 Tạo Order</span>:
                  có thể được tạo bởi [Admin, Sub Admin]
                  <ul>
                    <li>Người dùng đăng nhập vào hệ thống thành công bằng 1 trong 2 quyền [admin, sub_admin]</li>
                    <li>Nhấn chọn thẻ "DS order" bên góc menu trái màn hình</li>
                    <li>Nhập (chọn bàn, giờ vào, mô tả, món, giá tiền, đơn vị số lượng, tổng tiền)</li>
                    <li>Nhấn nút "Save" ==>Order được tạo và xuất thông báo thành công bên góc phải màn hình</li>
                  </ul>
                </div>
                <div class="col">
                  <span class="h6">1.2.3 Xem báo cáo doanh số</span>:
                   chỉ có Admin có thể xem
                   <ul>
                    <li>Người dùng đăng nhập vào hệ thống thành công bằng 1 trong 2 quyền [admin]</li>
                    <li>Nhấn chọn thẻ "Báo cáo-> doanh thu ngày" bên góc menu trái màn hình</li>
                    <li>Nhập (chọn chi nhánh,từ ngày,đến ngày)==>nhấn nút "Xem báo cáo" để xem báo cáo</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="h3 text-info">b. Hoạt động đa nền tản</div>
          <i class="ml-3">Hệ thống có thể làm việc trên tất cả thiết bị cầm tay có kết nối internet như:</i>
          <ul>
            <li>Máy tính bảng, Ipad, Tablet</li>
            <li>Điện thoại di động</li>
            <li>Máy tính sách tay, máy tính để bàn</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="h3 text-info">c. Báo giá sản phẩm: 2 tùy chọn</div>
          <table class="table table-bodered">
            <thead>
              <th class="text-center">Đăng ký tại <a class="text-primary" href="http://hansan.com">hansan</a></th>
              <th class="text-center">Cài đặt riêng một hệ thống cho công ty sử dụng</th>
            </thead>
            <tr>
              <td>
                <li>Miễn phí cho 100 ngày đầu sử dụng</li>
                <li>Sau 100 ngày miễn phí, phí chỉ 50k/user/tháng</li>
                <li>Hổ trọ khách hàng đăng ký và kích hoạt tài khoản</li>
              </td>
              <td>
                <li><a class="text-primary" href="http://hansan.com">hansan</a> miễn phí cài đặt hệ thống mới, dựa trên yêu cầu của khách hàng</li>
                <li>Phí duy trì server và quản trị hệ thống 500k/tháng</li>
                <li>không giới hạn số lượng user sử dụng</li>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="h3 text-info">d. Hổ trợ cài đặt:</div>
          <ul>
            <li>Hổ trợ đăng ký mới user và kích hoạt user</li>
            <li>Hướng dẫn người dùng sử dụng hệ thống</li>
          </ul>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CamdoDetail'
}
</script>
